import React, { useState } from 'react';
import './NavMenu.css';
import logoWebp from '../assets/brand/logo-webda.webp';

function NavMenu() {
  const [showModal, setShowModal] = useState(false); // Estado para controlar se o modal está aberto ou fechado

  // Função para manipular o clique no botão
  const handleButtonClick = () => {
    // Redirecionar para a URL desejada
    window.location.href = 'https://ixc.souuni.com/central_assinante_web/login';
  };

  // Função para mostrar o modal quando o link "Telefonia Fixa" é clicado
  const handleTelefoniaFixaClick = () => {
    setShowModal(true);
  };

  // Função para fechar o modal
  const handleCloseModal = () => {
    setShowModal(false);
  };

  return (
    <div>
      {/* Itens adicionais à esquerda do nav-menu */}
      <div className="extra-items">
        <a href="#atendimento">Atendimento</a>
        <a href="https://ixc.souuni.com/central_assinante_web/login">2ª Via de Fatura</a>
        <a href="#uni">Conheça a Uni</a>
      </div>
      {/* Nav menu */}
      <nav className="nav-menu">
        <img src={logoWebp} alt="Logo" className="logo" />
        <div className="menu-container">
          <a href="#combos" className="menu-item">Combo Completo</a>
          <a href="#tvhd" className="menu-item">TV HD</a>
          {/* <a href="#planos" className="menu-item">Só Internet</a> */}
          <a href="#" className="menu-item" onClick={handleTelefoniaFixaClick}>Telefonia Fixa</a> {/* Adiciona o evento onClick para mostrar o modal */}
        </div>
        <button className="btn" onClick={handleButtonClick}>Assinante</button>
        <div className="footer"></div>
      </nav>

      {/* Modal */}
      {showModal && (
        <div className="modal-overlay" onClick={handleCloseModal}>
          <div className="modal" onClick={(e) => e.stopPropagation()}>
            <span className="close" onClick={handleCloseModal}>&times;</span> {/* Botão para fechar o modal */}
            <p>Em breve...</p> {/* Conteúdo do modal */}
          </div>
        </div>
      )}
    </div>
  );
}

export default NavMenu;
